// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
import * as Turbo from '@hotwired/turbo'
Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import '../javascripts/jquery.js';
import '../javascripts/jquery.backstretch.min.js';
import 'bootstrap/js/dist/index.js';
import '../javascripts/owl.carousel.min.js';
import '../javascripts/js.cookie.js';
import '../javascripts/cookie_consent_banner.js';
import '../javascripts/frontpage.js';
import 'bootstrap/dist/js/bootstrap.js';


//= require jquery
//= require jquery.backstretch.min
//= require bootstrap/js/dist/index.js
//= require owl.carousel.min
//= require js.cookie
//= require cookie_consent_banner
//= require frontpage
//= require rails-ujs
//= require bootstrap/dist/js/bootstrap

