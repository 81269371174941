
$('.navbar').addClass("sticky-top");
setTimeout(function () {
  $('#intro-header-1').css({ "visibility": "visible" });
  $('#intro-header-1').addClass('animated fadeInUp');
}, 500
);
setTimeout(function () {
  $('#intro-header-2').css({ "visibility": "visible" });
  $('#intro-header-2').addClass('animated fadeInUp');
  $('#intro-line').css({ "visibility": "visible" });
  $('#intro-line').addClass('animated fadeInUp');
}, 1500
);
/*  var toggleAffix = function(affixElement, scrollElement) {
var height = affixElement.outerHeight();
if (scrollElement.scrollTop() <= 0 && affixElement.outerWidth() > 980){
  affixElement.addClass("affixed");
  }
  else {
  affixElement.removeClass("affixed");
  }
  };
  $('[data-toggle="affix"]').each(function() {
  var ele = $(this);
  $(window).on('scroll resize', function() {
  toggleAffix(ele, $(this));
  });
  // init
  toggleAffix(ele, $(window));
  });
  */
function scroll_to(clicked_link, nav_height) {
  var element_class = clicked_link.attr('href').replace('#', '.');
  var scroll_to = 0;
  if (element_class != '.top-content') {
    element_class += '-container';
    scroll_to = $(element_class).offset().top - nav_height;
  }
  if ($(window).scrollTop() != scroll_to) {
    $('html, body').stop().animate({ scrollTop: scroll_to }, 1000);
  }
}
//jQuery(document).ready(function() {

document.addEventListener("turbo:frame-load", function () {
  $('#pdfModal').modal('show');
});
document.addEventListener("turbo:load", function () {
  /*
  Navigation
  */
  $('a.scroll-link').on('click', function (e) {
    e.preventDefault();
    scroll_to($(this), 0);
  });
  /*
  Background slideshow
  */
  $('.top-section').backstretch("<%=image_url('backgrounds/MotherDaughter.png')%>", { alignX: 0, alignY: .8, scale: "cover" });
  $('.more-features-section').backstretch("<%=image_url('backgrounds/SittingByRiver.jpg')%>", { alignX: .5, alignY: .8 });
  $(window).resize(function () {
    $('.top-content').backstretch("resize");
    $('.more-features-container').backstretch("resize");
  });
  /*
  Wow
  */
  //new WOW().init();
  /*
  Modals
  */
  $('.launch-modal').on('click', function (e) {
    e.preventDefault();
    $('#' + $(this).data('modal-id')).modal();
  });
});
//jQuery(window).load(function() {
document.addEventListener("turbo:load", function () {
  $("#videoModal").on('hidden.bs.modal', function (e) {
    $("#videoModal iframe").attr("src", $("#videoModal iframe").attr("src"));
  });
  $('.owl-carousel').owlCarousel({
    loop: true,
    margin: 10,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        dots: true
      },
      320: {
        items: 2,
        dots: true,
        nav: false
      },
      576: {
        items: 3,
        dots: true,
        nav: false
      },
      768: {
        items: 4,
        dots: true,
        nav: false
      },
      992: {
        items: 4,
        dots: true,
        nav: true
      },
      1140: {
        items: 5,
        nav: true,
        ots: true,
        loop: false
      }
    }
  });
  /*
  Loader
  */
  $(".loader-img").fadeOut();
  $(".loader").fadeOut("slow");
  /*
  Hidden images
  */
  $(".modal-body img, .testimonial-image img").attr("style", "width: auto !important; height: auto !important;");
});
